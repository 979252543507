// Third Party
import { Component, Inject, ViewChild, ElementRef } from '@angular/core'
import { MatDialogRef, MatDialogConfig, MatDialog, MAT_DIALOG_DATA } from '@angular/material'
import { GridComponent, PageChangeEvent, SelectionEvent, DetailExpandEvent, DetailCollapseEvent } from '@progress/kendo-angular-grid'
import { process, GroupDescriptor, DataResult } from '@progress/kendo-data-query'
import moment, { Moment } from 'moment'
import { FormGroup, FormControl, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'

// shared
import { IDiscussion, IUser } from 'shared-ragasa-connect/interfaces'

// own interfaces
import { IDiscussionPayload } from '../../models/domain/general'
// services
import { DiscussionService } from '../../services'

// components
import { AuthService } from 'src/app/modules/auth/shared'

// extended interfaces
interface IDiscussionExt extends IDiscussion{
  type: string
}

@Component({
  selector: 'app-discussion-dialog',
  templateUrl: './discussion-modal.component.html',
  styleUrls: ['./discussion-modal.component.scss']
})

export class DiscussionComponent {
  protected closeCallback: (...args: any[]) => any

  isloading:boolean = false

  _data: IDiscussionPayload

  discussions: IDiscussionExt[] = []

  frmSchedule: FormGroup = null

  user: IUser

  constructor (
    private dialogRef: MatDialogRef<DiscussionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDiscussionPayload,
    private fb: FormBuilder,
    private discussionService:DiscussionService,
    private auth: AuthService
  ) {
    this.user = this.auth.getUser()
    this.frmSchedule = this.fb.group({
      comments: fb.control('')
    })
    this._data = data
    this.initialize(data)
  }

  initialize (data: IDiscussionPayload) {
    this.isloading = true
    this.discussionService.getDiscussions(
      data.code,
      data.idObject
    )
      .subscribe((response) => {
        this.discussions = []
        if (response && response.length > 0) {
          this.discussions = (response).map(el => {
            const user:string = (el.createdBy as IUser).user
            return {
              ...el,
              type: (this.user.user === user) ? 'discussion-card-container-start' : 'discussion-card-container-end'
            }
          })
        }
      }).add(() => {
        this.isloading = false
      })
  }

  getObjValue<T> (obj, name: string) {
    return obj[name] as T
  }

  close () {
    this.dialogRef.close()
  }

  onSend (event) {
    const comments: string = this.getFormControlValue('comments')
    const discussion: IDiscussion = {
      code: this._data.code,
      objectRef: this._data.idObject,
      comments
    }
    this.dialogRef.close(discussion)
  }

  setFormControlValue<T> (name: string, value: T) {
    this.frmSchedule.controls[name].setValue(value)
  }

  getFormControlValue<T> (name: string) {
    return this.frmSchedule.controls[name].value as T
  }
}
