import { intParser } from '../int-parser'
import * as _ from 'lodash'

export function arrayIntParser (value) {
  const returnVal = []
  if (value === undefined) {
    return undefined
  }

  const array = (value instanceof Array ? value : [value]) as any[]

  array.forEach(v => {
    returnVal.push(intParser(v))
  })

  return _.some(returnVal, (v) => v === undefined) ? undefined : returnVal
}
