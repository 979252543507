/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  message: string;

  constructor (public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
    // Update view with given values
    this.title = data.title
    this.message = data.message
  }

  onConfirm (): void {
    // Close the dialog, return true
    this.dialogRef.close(true)
  }

  onDismiss (): void {
    // Close the dialog, return false
    this.dialogRef.close(false)
  }

  ngOnInit () {

  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {
  constructor (public title: string, public message: string) {
  }
}
