import { SettingService } from './settings/setting.service'
import { StorageService } from './storage'
import { NavigationService } from './navigation'
import { AzFilesService } from './azfiles'
import { DiscussionService } from './discussion.service'
import { AnalitycsService } from './analitycs/analitycs.service'

export const SERVICES = [
  StorageService,
  SettingService,
  NavigationService,
  AzFilesService,
  DiscussionService,
  AnalitycsService
]

export {
  StorageService,
  NavigationService,
  SettingService,
  AzFilesService,
  DiscussionService,
  AnalitycsService
}
