import { AuthService } from './auth.service/auth.service'
import { IDialog } from './dialog.interface'
import { dialogType } from './dialog.type'

export const AUTH_SERVICES = [
  AuthService
]

export {
  AuthService,
  IDialog,
  dialogType
}
