/* eslint-disable no-useless-constructor */
// Third party
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http'
import { CommonModule } from '@angular/common'
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { TranslateModule, TranslateLoader, TranslateService, TranslateStore } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'

// Components
import { COMPONENTS } from './components'
import { DiscussionComponent } from './components/discussion-modal'
import { ConfirmDialogComponent } from './components/confirm-dialog'

// Services
import { SERVICES } from './services'
import { AuthService } from './../modules/auth/shared'

// Guards
import { GUARDS } from './guards'

// Directives
import { DIRECTIVES } from './directives'

// Material
import { FlexLayoutModule } from '@angular/flex-layout'
import {
  MatProgressBarModule,
  MatInputModule,
  MatFormFieldModule,
  MatButtonModule,
  MatIconModule,
  MatCardModule,
  MatExpansionModule,
  MatChipsModule,
  MatSelectModule,
  MatDatepickerModule,
  MatAutocompleteModule,
  MatTooltipModule,
  MatDialogModule,
  MatDialogRef,
  MatRippleModule,
  MatIconRegistry
} from '@angular/material'
import {
  MatMomentDateModule
} from '@angular/material-moment-adapter'

// Interceptors
import {
  HttpErrorInterceptor,
  HttpAuthenticationInterceptor,
  HttpSessionExpiredInterceptor
} from './interceptors'
import { use } from 'chai'
// Para clases puras no se utiliza el export

export function createTranslateLoader (http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}
declare global {
  interface Window {
    WebChat: any;
  }
}

@NgModule({
  declarations: [
    ...DIRECTIVES,
    ...COMPONENTS
  ],
  imports: [
    MatDialogModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatExpansionModule,
    MatChipsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatAutocompleteModule,
    MatTooltipModule,
    GridModule,
    ExcelModule,
    FlexLayoutModule,
    MatRippleModule,
    HttpClientModule,
    CommonModule,
    MatProgressBarModule,
    MatDialogModule,
    MatIconModule,
    FormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      isolate: true
    })
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  entryComponents: [
    DiscussionComponent,
    ConfirmDialogComponent
  ],
  providers: [
    ...SERVICES,
    ...GUARDS,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthenticationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpSessionExpiredInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    TranslateService,
    TranslateStore
  ],
  exports: [
    ...COMPONENTS,
    ...DIRECTIVES,
    TranslateModule
  ]
})
export class SharedModule {
  constructor (
      private translate: TranslateService,
      private authService: AuthService
  ) {
    this.getLanguageForUser()
  }

  getLanguageForUser () {
    const userLanguage = this.authService.getLanguageUser()
    if (userLanguage === null) {
      const browserLang = this.translate.getBrowserLang()
      this.translate.use(browserLang.match(/en|es/) ? browserLang : 'es')
      this.translate.setDefaultLang(browserLang.match(/en|es/) ? browserLang : 'es')
    } else {
      this.translate.use(userLanguage)
      this.translate.setDefaultLang(userLanguage)
    }
  }
}
