// Third Party
import { Component, Inject, ViewChild, ElementRef } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { GridComponent, PageChangeEvent, SelectionEvent, DetailExpandEvent, DetailCollapseEvent } from '@progress/kendo-angular-grid'
import { process, GroupDescriptor, DataResult } from '@progress/kendo-data-query'
// Own Services
import { AzFilesService } from '../../services'
// Own Functions
import { headerGridStyles, rowGridStyles, inlineStyles } from '../../../utils'
// Own Interfaces
import { IDownloadFilesPayload } from '../../models/domain/general'
// Shared
import { IUploadfileFilters } from 'shared-ragasa-connect/interfaces'

@Component({
  selector: 'app-download-diaglog',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})

export class DownloadComponent {
  @ViewChild('downloadLink', { static: false }) downloadLink: ElementRef
  @ViewChild('kendoGrid', { static: false }) kendoGrid: GridComponent
  @ViewChild('imageIcon', { static: false }) imageIcon: ElementRef

  readonly inlineStyles = inlineStyles

  rowStylesCallback = rowGridStyles

  headerStyles = headerGridStyles()

  isloading:boolean = false

  docTypeImage:string = '../../../../../assets/img/docs/pdf.png'

  description: string = ''

  _listFiles: IUploadfileFilters[]

  base64: string = ''

  constructor (
    private azFilesService: AzFilesService,
    private dialogRef: MatDialogRef<DownloadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDownloadFilesPayload) {
    this.description = data.description
    this._listFiles = data.files
  }

  close () {
    this.dialogRef.close()
  }

  download (item: IUploadfileFilters) {
    this.isloading = true

    if (item.path) {
      [item.year,
        item.month,
        item.day,
        item.documentCategory
      ] = item.path.split('/')
    }

    this.azFilesService.getAzFile(
      item.container,
      item.year,
      item.month,
      item.day,
      item.documentCategory,
      item.filename,
      item.ext)
      .subscribe((resp: any) => {
        this.downloadLink.nativeElement.href = getBase64Prefix(item.ext) + resp
        this.downloadLink.nativeElement.download = item.documentCategory + '_' + item.filename + '.' + item.ext
        this.downloadLink.nativeElement.click()
      })
      .add(() => {
        this.isloading = false
      })
  }

  onCellClick (e: any) {
    this.docTypeImage = '../../../../../assets/img/docs/' + e.dataItem.ext + '.png'
  }
}

function getBase64Prefix (fileExt: string) {
  const ext = fileExt.toLowerCase()
  switch (ext) {
    case 'jpg':
    case 'png':
      return 'data:image/' + ext + ';base64,'
    case 'pdf':
      return 'data:application/pdf;base64,'
    case 'xml':
      return 'data:aplication/xml;base64,'
    case 'txt':
      return 'data:text/plain;base64,'
  }
}
