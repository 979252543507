/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-constructor */
// Third Party
import { Component, ViewChild, ElementRef, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { Router } from '@angular/router'

// Own Services
import { AuthService, IDialog, dialogType } from '../../shared'

// Own Components
import { DialogComponent } from '../../components'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'container-password-recovery',
  templateUrl: './password-recovery.container.html',
  styleUrls: ['./password-recovery.container.scss']
})
export class PasswordRecoveryContainer implements OnInit {
  isLoading: boolean = false

  loadingMessage: string = null

  dialog: IDialog = null

  @Output() mailSended: EventEmitter<void> = new EventEmitter()

  @ViewChild('dialogComponent', { static: false })
  dialogComponent: ElementRef

  constructor (
    private authService: AuthService,
    private router: Router,
    private translate: TranslateService
  ) {
  }

  ngOnInit () {
    const browserLang = this.translate.getBrowserLang()
    this.translate.use(browserLang.match(/en|es/) ? browserLang : 'en')
  }

  onPasswordRecovery (username: string) {
    this.closeDialog()
    this.sendInstructions(username)
  }

  displayMessage (type: dialogType, message: string, timeOut?: number, showCloseButton?: boolean) {
    this.dialog = {
      type,
      message,
      timeOut,
      showCloseButton
    }
  }

  closeDialog () {
    if (this.dialogComponent instanceof DialogComponent) {
      (this.dialogComponent as DialogComponent).close()
    }
  }

  sendInstructions (username: string) {
    this.loadingMessage = this.translate.instant('login.passwordRecoveryMessage')
    this.authService
      .sendInstructiondByEmail(username)
      .subscribe(() => {
        this.displayMessage('success', this.translate.instant('login.passwordRecoveryMessageSuccess'))
      }, () => {
        this.displayMessage('success', this.translate.instant('login.passwordRecoveryMessageSuccess'))
      })
    this.displayMessage('success', this.translate.instant('login.passwordRecoveryMessageSuccess'))
  }

  onError (error: string) {
    this.displayMessage('error', error, null, true)
  }

  goToLogin () {
    this.router
      .navigate(['auth', 'login'], {
      })
  }
}
