/* eslint-disable no-unused-vars */
// Third party
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http'
import { Observable, throwError, TimeoutError } from 'rxjs'
import { retry, catchError, timeout, map } from 'rxjs/operators'

export class HttpSessionExpiredInterceptor implements HttpInterceptor {
  intercept (request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('Session Expired Interceptor')
    return next.handle(request)
  }
}
