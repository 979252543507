/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
// Third party
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core'

// Own Models
import { IDialog } from '../../shared'

@Component({
  selector: 'component-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  timer: NodeJS.Timer = null

  _dialog: IDialog = null

  @Input()
  set dialog (dialog: IDialog) {
    this.showDialog(dialog)
  }

  @Output()
  onCloseDialog: EventEmitter<boolean> = new EventEmitter()

  constructor () {}

  ngOnInit () {
  }

  setDialogDefaults (dialog: IDialog): IDialog {
    return {
      type: dialog.type,
      message: dialog.message,
      showCloseButton: dialog.showCloseButton === undefined || dialog.showCloseButton === null ? true : dialog.showCloseButton,
      timeOut: dialog.timeOut ? null : dialog.timeOut
    }
  }

  showDialog (dialog: IDialog) {
    if (dialog) {
      this._dialog = this.setDialogDefaults(dialog)

      if (dialog.timeOut) {
        this.timer = setTimeout(() => {
          this.close()
        }, dialog.timeOut)
      }
    } else {
      this.close()
    }
  }

  onClickCloseButton (event) {
    this.close()
  }

  public close () {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this._dialog = null
    if (this.onCloseDialog) {
      this.onCloseDialog.emit(true)
    }
  }
}
