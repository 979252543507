/* eslint-disable no-unused-vars */
import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

// Own Guards
import { ValidateTokenGuard } from '../../shared/guards'

// Containers
import {
  AuthContainer,
  LoginContainer,
  ActivationCodeContainer,
  PasswordRecoveryContainer,
  ResetPasswordContainer
} from './containers'

const routes: Routes = [
  {
    path: '',
    component: AuthContainer,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginContainer
      },
      {
        path: 'activation-code',
        component: ActivationCodeContainer,
        canActivate: [ValidateTokenGuard]
      },
      {
        path: 'password-recovery',
        component: PasswordRecoveryContainer
      },
      {
        path: 'reset-password',
        component: ResetPasswordContainer
      }
    ]
  }
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
