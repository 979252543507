/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-constructor */

// Third party
import { Injectable } from '@angular/core'
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,

  HttpResponse,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http'
import { Observable, of, throwError, TimeoutError, timer } from 'rxjs'
import { map, retry, catchError, timeout, retryWhen, mergeMap, finalize } from 'rxjs/operators'
import { Router } from '@angular/router'

// shared ragasa
import { IUser } from 'shared-ragasa-connect/interfaces'

// our services
import { AuthService } from '../../../modules/auth/shared/auth.service'

// Constants
import { HTTP_STRATEGIES, HTTP_STRATEGY, HTTP_STRATEGY_DEFAULT, BYPASS_END_SESSION_HEADER } from '../constants'
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  bypassEndSession: Boolean = false
  httpStrategy: any

  constructor (
    private authService: AuthService,
    private router: Router
  ) {}

  navigateToLogin () {
    this.router
      .navigate(['auth', 'login'])
  }

  intercept (request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let strategy: string = HTTP_STRATEGY_DEFAULT
    let headers: HttpHeaders = request.headers

    this.bypassEndSession = headers.has(BYPASS_END_SESSION_HEADER)
    if (this.bypassEndSession) {
      headers = headers.delete(BYPASS_END_SESSION_HEADER)
    }

    if (headers.has(HTTP_STRATEGY)) {
      strategy = request.headers.get(HTTP_STRATEGY)
    }

    this.httpStrategy = HTTP_STRATEGIES.find(h => (h.name === strategy))

    const genericRetryStrategy = ({
      maxRetryAttempts = this.httpStrategy.retryCount,
      scalingDuration = this.httpStrategy.scalingDuration,
      excludedStatusCodes = [500, 401]
    }: {
      maxRetryAttempts?: number,
      scalingDuration?: number,
      excludedStatusCodes?: number[]
    } = {}) => (attempts: Observable<any>) => {
      return attempts.pipe(

        mergeMap((error: HttpErrorResponse, i) => {
          const retryAttempt = i + 1
          if (retryAttempt > maxRetryAttempts) {
            return throwError(error)
          }
          if (excludedStatusCodes.find(e => e === error.status)) {
            if ((error.error.error.message).indexOf('Topology was destroyed') === -1) {
              return throwError(error)
            }
          }
          console.log(
            `Attempt ${retryAttempt}: retrying in ${retryAttempt *
              scalingDuration}ms`
          )
          return timer(retryAttempt * scalingDuration)
        }),
        finalize(() => console.log('We are done!'))
      )
    }
    const tim:number = Date.now()
    return next
      .handle(request)
      .pipe(
        map((evt) => {
          if (evt instanceof HttpResponse) {
            // this.logs.endLog(evt.url)
            if (evt.url.toString().indexOf('integration.southcentralus') > -1 || evt.url.toString().indexOf('api.azurewebsites') > -1) {
              console.log('Tiempo: ' + evt.url, Date.now() - tim)
            }
          }
          return evt
        }),
        timeout(this.httpStrategy.timeout),
        retry(this.httpStrategy.retryCount),
        retryWhen(genericRetryStrategy({
          maxRetryAttempts: this.httpStrategy.retryCount,
          scalingDuration: this.httpStrategy.scalingDuration,
          excludedStatusCodes: [500, 401]
        })),
        catchError((error: HttpErrorResponse) => {
          const timeoutValue = request.headers.get('timeout')
          console.log('interceptor url: ', request.url)
          console.log('interceptor time out: ', timeoutValue)

          let errorMessage = ''
          if (error.status === 0) {
            errorMessage = 'Error desconocido'
          } else if (error && error.error && error.error.error && error.error.error.message) {
            errorMessage = error.error.error.message
            if (error.status === 401 && !this.bypassEndSession) {
              const user: IUser = this.authService.getUser()
              if (user !== null) {
                // this.authService.clearLoginInfo()
                this.navigateToLogin()
                return of({} as HttpResponse<any>)
              }
            }
          } else if (error instanceof TimeoutError) {
            errorMessage = error.status + ' Se ha terminado el tiempo de espera.'
          } else {
            errorMessage = 'Error desconocido'
          }
          return throwError(new Error(errorMessage))
        })

      )
  }
}
