import { LoadingComponent } from './loading'
import { LogoComponent } from './logo'
import { DownloadComponent } from './download'
import { NotificationsComponent } from './notifications'
import { DiscussionComponent } from './discussion-modal'
import { ConfirmDialogComponent } from './confirm-dialog'

export const COMPONENTS = [
  LoadingComponent,
  LogoComponent,
  DownloadComponent,
  NotificationsComponent,
  DiscussionComponent,
  ConfirmDialogComponent
]

export {
  LoadingComponent,
  LogoComponent,
  DownloadComponent,
  NotificationsComponent,
  DiscussionComponent,
  ConfirmDialogComponent
}
