/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-constructor */
// Third Party
import { Component, ViewChild, ElementRef, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

// Own Services
import { AuthService, IDialog, dialogType } from '../../shared'
import { NavigationService } from '../../../../shared/services'

// Own Components
import { DialogComponent } from '../../components'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'container-activation-code',
  templateUrl: './activation-code.container.html',
  styleUrls: ['./activation-code.container.scss']
})
export class ActivationCodeContainer implements OnInit {
  LOGIN_URL = '/auth/login'

  isLoading: boolean = false

  loadingMessage: string = null

  dialog: IDialog = null

  @Input() isFromEndSession: boolean = false

  @Output() goHome: EventEmitter<void> = new EventEmitter()

  @ViewChild('dialogComponent', { static: false })
  dialogComponent: ElementRef

  constructor (
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    private translate: TranslateService
  ) {
  }

  ngOnInit () {
    this.sendCodeByEmail(false)
    const browserLang = this.translate.getBrowserLang()
    this.translate.use(browserLang.match(/en|es/) ? browserLang : 'en')
  }

  onSubmitCode (code: string) {
    this.closeDialog()
    this.validateCode(code)
  }

  onError (error: string) {
    this.displayMessage('error', error, null, true)
  }

  displayMessage (type: dialogType, message: string, timeOut?: number, showCloseButton?: boolean) {
    this.dialog = {
      type,
      message,
      timeOut,
      showCloseButton
    }
  }

  closeDialog () {
    if (this.dialogComponent instanceof DialogComponent) {
      (this.dialogComponent as DialogComponent).close()
    }
  }

  onResendCodeByEmail () {
    this.sendCodeByEmail(true)
  }

  sendCodeByEmail (newCode: boolean) {
    this.loadingMessage = this.translate.instant('activationCode.loadingMessage')
    this.isLoading = true
    this.authService
      .sendCodeByMail(newCode)
      .subscribe(() => {
        this.displayMessage('success', this.translate.instant('activationCode.successMessage'))
      }, (error: Error) => {
        this.displayMessage('error', error.message)
      })
      .add(() => {
        this.isLoading = false
      })
  }

  validateCode (code: string) {
    this.loadingMessage = this.translate.instant('activationCode.validateCode')
    this.isLoading = true
    this.authService
      .activationCode(code)
      .subscribe(active => {
        this.goToHome()
      }, (error: Error) => {
        this.displayMessage('error', error.message)
      })
      .add(() => {
        this.isLoading = false
      })
  }

  async goToHome () {
    if (this.isFromEndSession) {
      this.goHome.emit()
    } else {
      await this.router.navigate(['/', 'home'])
    }
  }
}
