/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-constructor */

import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { IUser, ISession, IRole, IModule, IAction, IClient, ISettingsUser } from 'shared-ragasa-connect/interfaces'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'

// Own Models
import { ILoginModel } from '../../../../shared/models/domain'

// Own Services
import { StorageService } from '../../../../shared/services'

// Environment
import { environment } from '../../../../../environments/environment'

// Utils
import { convertBase64ToType } from '../../../../utils'

// Constants
import { SKIP_TOKEN_HEADER, BYPASS_END_SESSION_HEADER, HTTP_STRATEGY_READ_LOCAL, HTTP_STRATEGY_READ_REMOTE, HTTP_STRATEGY_WRITE_REMOTE } from '../../../../shared/interceptors/constants'

import { CONST_TOKEN_KEY, CONST_USER_KEY, CONST_SESSION_ACTIVE_KEY, CONST_USER_LANGUAGE } from './../../../../shared/constants/storage'
import { CONST_PLATFORM, CONST_PLATFORM_MOBILE } from '../../../../shared/constants/settings'

@Injectable()
export class AuthService {
  private API_URL = environment.apiUrl

  constructor (
    private http: HttpClient,
    private storageService: StorageService,
    private translate: TranslateService
  ) {
  }

  // Authentication Methods
  setLoginInfo (token: string): void {
    const user: IUser = convertBase64ToType<IUser>(token.split('.')[1])
    this.storageService.setLocalObject<string>(CONST_TOKEN_KEY, token)
    this.setUser(user)
  }

  setUser (user: IUser): void {
    this.storageService.setLocalObject<IUser>(CONST_USER_KEY, user)
  }

  setSessionStatus (active: boolean): void {
    this.storageService.setLocalObject(CONST_SESSION_ACTIVE_KEY, active)
  }

  getUser (): IUser {
    if (this.isLoggedIn()) {
      return this.storageService.getLocalObject<IUser>(CONST_USER_KEY)
    }
    return null
  }

  setLanguageUser (lang: string): string {
    if (this.isLoggedIn()) {
      this.storageService.setLocalObject(CONST_USER_LANGUAGE, lang)
    }
    return null
  }

  removeLanguageUser (): void {
    this.storageService.removeLocalObject(CONST_USER_LANGUAGE)
  }

  getLanguageUser (): string {
    if (this.isLoggedIn()) {
      return this.storageService.getLocalObject<string>(CONST_USER_LANGUAGE)
    }
    return null
  }

  getToken (): string {
    if (this.isLoggedIn()) {
      return this.storageService.getLocalObject<string>(CONST_TOKEN_KEY)
    }
    return null
  }

  isLoggedIn (): boolean {
    if (this.storageService.getLocalObject<string>(CONST_TOKEN_KEY)) {
      return true
    } else {
      return false
    }
  }

  isSessionActivated (): boolean {
    return !!this.storageService.getLocalObject<string>(CONST_SESSION_ACTIVE_KEY)
  }

  userLoggedRequireCode (): boolean {
    if (this.isLoggedIn()) {
      const user = this.getUser()
      return user.isCodeRequiredForLogin
    } else {
      throw new Error('Log in is required to perform this action.')
    }
  }

  clearLoginInfo (): void {
    this.storageService.removeLocalObject(CONST_TOKEN_KEY)
    this.storageService.removeLocalObject(CONST_USER_KEY)
    this.storageService.removeLocalObject(CONST_SESSION_ACTIVE_KEY)
  }

  getUserRole (): IRole {
    if (this.isLoggedIn()) {
      return this.storageService.getLocalObject<IUser>(CONST_USER_KEY).role as IRole
    }
    throw new Error('You must been logged in')
  }

  getUserActions (): string[] {
    if (this.isLoggedIn()) {
      let actions: string[] = []
      const role = this.getUserRole()
      const modules = role && role.modules instanceof Array && (role.modules as IModule[])
      modules.forEach((m: IModule) => {
        actions = actions.concat(
          (m &&
            m.actions instanceof Array &&
            (m.actions as IAction[])
              .map((a: IAction) => a.code)
          )
        )
      })

      return actions
    }
    return []
  }

  getUserClients (): IClient[] {
    if (this.isLoggedIn()) {
      return this.storageService.getLocalObject<IUser>(CONST_USER_KEY).clients as IClient[]
    }
    throw new Error('You must been logged in')
  }

  // API Methods
  sendCodeByMail (newCode: Boolean): Observable<void> {
    const body = {
      newCode
    }
    const headers: HttpHeaders = new HttpHeaders({
      platform: CONST_PLATFORM,
      httpStrategy: HTTP_STRATEGY_READ_LOCAL
    })
    return this.http
      .post(this.API_URL + 'mail/send/code', body, { headers })
      .pipe(
        map(resp => {

        })
      )
  }

  sendInstructiondByEmail (username: string): Observable<any> {
    const body = {
      username
    }
    const headers: HttpHeaders = new HttpHeaders({
      [SKIP_TOKEN_HEADER]: SKIP_TOKEN_HEADER,
      platform: CONST_PLATFORM,
      httpStrategy: HTTP_STRATEGY_READ_LOCAL
    })
    console.log('ENDPOINT = ', this.API_URL + 'mail/recovery-password')
    return this.http
      .post(this.API_URL + 'mail/recovery-password', body, { headers })
      .pipe(
        map(resp => {
        })
      )
  }

  resetPassword (token: string, password: string): Observable<any> {
    const body = {
      token: token,
      password: password
    }
    const headers: HttpHeaders = new HttpHeaders({
      [SKIP_TOKEN_HEADER]: SKIP_TOKEN_HEADER,
      platform: CONST_PLATFORM,
      httpStrategy: HTTP_STRATEGY_READ_LOCAL
    })
    return this.http
      .post(this.API_URL + 'auth/resetpassword', body, { headers })
      .pipe(
        map(resp => {
        })
      )
  }

  login (authData: ILoginModel): Observable<IUser> {
    const headers: HttpHeaders = new HttpHeaders({
      [SKIP_TOKEN_HEADER]: SKIP_TOKEN_HEADER,
      [BYPASS_END_SESSION_HEADER]: BYPASS_END_SESSION_HEADER,
      platform: CONST_PLATFORM,
      httpStrategy: HTTP_STRATEGY_READ_REMOTE
    })

    return this
      .http
      .post(this.API_URL + 'auth/login', authData, { headers })
      .pipe(
        map(
          (resp: { token: string }) => {
            this.setLoginInfo(resp.token)
            return this.getUser()
          }
        )
      )
  }

  activationCode (code: string): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      [BYPASS_END_SESSION_HEADER]: BYPASS_END_SESSION_HEADER,
      platform: CONST_PLATFORM,
      httpStrategy: HTTP_STRATEGY_READ_LOCAL
    })

    return this
      .http
      .post(this.API_URL + 'auth/activation-code', { code }, { headers })
      .pipe(
        map(
          (resp: ISession) => {
            this.setSessionStatus(resp.active)
            return resp.active
          }
        )
      )
  }

  isSessionActive (): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      [BYPASS_END_SESSION_HEADER]: BYPASS_END_SESSION_HEADER,
      platform: CONST_PLATFORM,
      httpStrategy: HTTP_STRATEGY_READ_LOCAL
    })

    return this.http.get(this.API_URL + 'auth/status', { headers })
      .pipe(
        map(() => {
          return true
        })
      )
  }

  logout (platform): Observable<void> {
    const headers: HttpHeaders = new HttpHeaders({
      platform: CONST_PLATFORM,
      httpStrategy: HTTP_STRATEGY_READ_LOCAL,
      remove_session: platform ? CONST_PLATFORM_MOBILE : null
    })

    return this.http
      .post(this.API_URL + 'auth/logout', {}, { headers })
      .pipe(
        map(resp => {
          if (!platform) {
            this.clearLoginInfo()
          }
        })
      )
  }
}
