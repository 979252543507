/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-constructor */
// Third Party
import { environment } from '../../../../environments/environment'
import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { ISetting } from 'shared-ragasa-connect/interfaces'
// Own Constants
import { SKIP_TOKEN_HEADER } from '../../interceptors/constants'
import { CONST_PLATFORM } from '../../constants/settings'
import { HTTP_STRATEGY_READ_REMOTE } from '../../../shared/interceptors/constants'

const headers: HttpHeaders = new HttpHeaders({
  platform: CONST_PLATFORM,
  httpStrategy: HTTP_STRATEGY_READ_REMOTE
})

@Injectable({
  providedIn: 'root'
})
export class AzFilesService {
  private apiURL = environment.apiUrl

  constructor (private http: HttpClient) {}

  getAzFileList (container: string, year:string, month: string, day: string, documentCategory:string): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiURL}getazfilelist/${container}/${year}/${month}/${day}/${documentCategory}`, { headers })
  }

  getAzFile (container: string, year:string, month: string, day: string, documentCategory:string, filename:string, ext: string): Observable<any> {
    return this.http.get(`${this.apiURL}getazfile/${container}/${year}/${month}/${day}/${documentCategory}/${filename}/${ext}`, { responseType: 'text', headers })
  }
}
