import { HttpParams } from '@angular/common/http'
import * as _ from 'lodash'

export function convertObjectToHttpParams (obj: any) {
  if (_.isPlainObject(obj)) {
    const params = new HttpParams({
      fromObject: obj
    })

    return params
  }
  return null
}
