/* eslint-disable no-useless-constructor */
// Third Party
import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { IUser } from 'shared-ragasa-connect/interfaces'
// Own Constants
import { CONST_USER_KEY } from './../../constants/storage'
// Own Services
import { StorageService } from './../../services'

@Injectable({
  providedIn: 'root'
})
export class LegalTermsGuard implements CanActivate {
  constructor (
    private storageService: StorageService,
    private router: Router
  ) { }

  canActivate (): boolean {
    const user = this.storageService.getLocalObject<IUser>(CONST_USER_KEY)
    if (user && user.legalTermsAccepted) {
      return true
    } else if (user) {
      this.router.navigate(['legal-terms'])
      return false
    } else {
      this.router.navigate(['auth'])
      return false
    }
  }
}
