import { ConnectionService } from 'ng-connection-service'
/* eslint-disable no-unused-vars */
// Third party
import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router'
import moment from 'moment'
import { Location } from '@angular/common'
import { environment } from '../environments/environment'
// Own Services
import { StorageService, NavigationService } from './shared/services'
import { AuthService } from './modules/auth/shared'

declare let gtag: (property: string, value: any, configs: any) => {}

@Component({
  selector: 'app-root',
  templateUrl: './app.container.html',
  styleUrls: ['./app.container.scss'],
  providers: [
    StorageService,
    NavigationService,
    AuthService
  ]
})
export class AppContainer implements OnInit {
  isLoading: boolean = false

  title = 'Some Title'

  isConnected = true

  public constructor (
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService,
    private connectionService: ConnectionService,
    private navigationService: NavigationService,
    private authService: AuthService,
    location: Location
  ) {
    moment.locale('es-mx')
    this.title = 'Ragasa Next'
    navigationService.addUrlToHistory(location.path())
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const urlHistory = event.urlAfterRedirects.split('/')
        if (urlHistory.length > 3) {
          event.url = `${urlHistory[0]}/${urlHistory[1]}/${urlHistory[2]}`
        }
        let url = event.urlAfterRedirects.includes('?') === true ? (event.url.split('?')[0]) : event.url
        if (url.length > 1) {
          url = url.replace('/home/', '')
          if (url !== 'shipments') {
            url = url.endsWith('s') ? url.slice(0, -1) : url
          }
        }
        url = url.replace('/', '')
        gtag('config', environment.googleAnalyticsId, {
          page_view: url,
          page_title: url
        })
      }
    })
  }

  ngOnInit () {
    // this.validateAuthentication()
    this.registerConnectionService()
  }

  validateAuthentication () {
    this.isLoading = true
    this.authService
      .isSessionActive()
      .subscribe(() => {
        // this.navigateToHome()
      }, () => {
        this.navigateToLogin()
      })
      .add(() => {
        this.isLoading = false
      })
  }

  registerConnectionService () {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected
      if (this.isConnected) {
        // this.toast.success('Se ha reestablecido su conexión a internet', '')
      } else {
        // this.toast.error('Se ha perdido su conexión a internet', '')
      }
    })
  }

  navigateToLogin () {
    this.router
      .navigate(['auth', 'login'], {
        relativeTo: this.activatedRoute
      })
  }

  navigateToHome () {
    this.router
      .navigate(['home'], {
        relativeTo: this.activatedRoute
      })
  }
}
