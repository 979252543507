/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-constructor */

// Third Party
import { Component, OnInit, Output, EventEmitter } from '@angular/core'
import { FormControl, Validators, FormBuilder, FormGroup, AbstractControl } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'component-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit {
  readonly errors = {
    required: this.translate.instant('login.emailRequire'),
    email: this.translate.instant('login.emailValue')
  }

  frmPasswordRecovery: FormGroup = null
  sent: boolean = null

  @Output() onPasswordRecovery: EventEmitter<string> = new EventEmitter<string>()

  @Output() onError: EventEmitter<string> = new EventEmitter<string>()

  constructor (
    fb: FormBuilder,
    private translate: TranslateService
  ) {
    this.frmPasswordRecovery = fb.group({
      passwordRecovery: new FormControl('', [
        Validators.required,
        Validators.email
      ])
    })
  }

  ngOnInit () {
    this.sent = false
    const browserLang = this.translate.getBrowserLang()
    this.translate.use(browserLang.match(/en|es/) ? browserLang : 'en')
  }

  getErrorMessage (control: AbstractControl): string {
    let message = ''
    for (const key in this.errors) {
      if (control.hasError(key)) {
        message = this.errors[key]
        break
      }
    }

    return message
  }

  getErrorMessages (form: FormGroup): string {
    let message = ''

    for (const key in form.controls) {
      message += this.getErrorMessage(form.controls[key] as FormControl)
    }

    return message
  }

  submitPasswordRecovery (frmPasswordRecovery: FormGroup) {
    if (frmPasswordRecovery.invalid) {
      this.onError.emit(this.getErrorMessages(frmPasswordRecovery))
      this.sent = false
    } else {
      this.onPasswordRecovery.emit(frmPasswordRecovery.controls.passwordRecovery.value.toLowerCase())
      this.sent = true
    }
  }
}
