/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-constructor */

// Third Party
import { Component, OnInit, Output, EventEmitter } from '@angular/core'
import { FormControl, Validators, FormBuilder, FormGroup, AbstractControl } from '@angular/forms'

@Component({
  selector: 'component-activation-code',
  templateUrl: './activation-code.component.html',
  styleUrls: ['./activation-code.component.scss']
})
export class ActivationCodeComponent implements OnInit {
  readonly errors = {
    required: 'El codigo es requerido',
    pattern: 'El codigo solo debe de contener letras y numeros',
    minlength: 'El codigo debe de ser de 6 caracteres',
    maxlength: 'El codigo debe de ser de 6 caracteres'
  }

  frmActivationCode: FormGroup = null

  @Output() onSubmitCode: EventEmitter<string> = new EventEmitter<string>()

  @Output() onError: EventEmitter<string> = new EventEmitter<string>()

  constructor (
    fb: FormBuilder
  ) {
    this.frmActivationCode = fb.group({
      activationCode: new FormControl('', [
        Validators.minLength(6),
        Validators.maxLength(6),
        Validators.required,
        Validators.pattern(/^[0-9a-zA-Z]+$/)
      ])
    })
  }

  ngOnInit () {

  }

  getErrorMessage (control: AbstractControl): string {
    let message = ''
    for (const key in this.errors) {
      if (control.hasError(key)) {
        message = this.errors[key]
        break
      }
    }

    return message
  }

  getErrorMessages (form: FormGroup): string {
    let message = ''

    for (const key in form.controls) {
      message += this.getErrorMessage(form.controls[key] as FormControl)
    }

    return message
  }

  submitCode (frmActivationCode: FormGroup) {
    if (frmActivationCode.invalid) {
      this.onError.emit(this.getErrorMessages(frmActivationCode))
    } else {
      this.onSubmitCode.emit(frmActivationCode.controls.activationCode.value.toUpperCase())
    }
  }
}
