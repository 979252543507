import { AuthContainer } from './auth'
import { LoginContainer } from './login'
import { ActivationCodeContainer } from './activation-code'
import { PasswordRecoveryContainer } from './password-recovery'
import { ResetPasswordContainer } from './reset-password'

export const AUTH_CONTAINERS = [
  AuthContainer,
  LoginContainer,
  ActivationCodeContainer,
  PasswordRecoveryContainer,
  ResetPasswordContainer
]

export {
  AuthContainer,
  LoginContainer,
  ActivationCodeContainer,
  PasswordRecoveryContainer,
  ResetPasswordContainer
}
