/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-constructor */
// Third Party
import { Injectable } from '@angular/core'

declare var localStorage: Storage
declare var sessionStorage: Storage
@Injectable()
export class StorageService {
  constructor () {
  }

  getLocalObject<T> (key: string): T {
    if (key === null || key === undefined || key.toString() === '') {
      throw new Error('key param is required.')
    }
    if (!localStorage) return
    return JSON.parse(localStorage.getItem(key)) as T
  }

  getSessionObject<T> (key: string): T {
    if (key === null || key === undefined || key.toString() === '') {
      throw new Error('key param is required.')
    }
    if (!sessionStorage) return
    return JSON.parse(sessionStorage.getItem(key)) as T
  }

  setLocalObject<T> (key: string, obj: T): void {
    if (key === null || key === undefined || key.toString() === '') {
      throw new Error('key param is required.')
    }
    localStorage.setItem(key, JSON.stringify(obj))
  }

  setSessionObject<T> (key: string, obj: T): void {
    if (key === null || key === undefined || key.toString() === '') {
      throw new Error('key param is required.')
    }
    sessionStorage.setItem(key, JSON.stringify(obj))
  }

  removeLocalObject (key: string): void {
    if (key === null || key === undefined || key.toString() === '') {
      throw new Error('key param is required.')
    }
    localStorage.removeItem(key)
  }

  removeSessionObject (key: string): void {
    if (key === null || key === undefined || key.toString() === '') {
      throw new Error('key param is required.')
    }
    sessionStorage.removeItem(key)
  }

  clearLocalStorage (): void {
    localStorage.clear()
  }

  clearSessionStorage (): void {
    sessionStorage.clear()
  }
}
