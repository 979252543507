/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-constructor */
// Third party
import { Injectable } from '@angular/core'
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http'
import { timeout } from 'rxjs/operators'
import { Observable, throwError } from 'rxjs'

// Own Services
import { AuthService } from '../../../modules/auth/shared/auth.service'

// Constants
import { SKIP_TOKEN_HEADER } from '../constants'

@Injectable()
export class HttpAuthenticationInterceptor implements HttpInterceptor {
  constructor (
    private authService: AuthService
  ) {
  }

  intercept (request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('Auth Interceptor')
    const headers = request.headers

    if (headers.has(SKIP_TOKEN_HEADER)) {
      headers.delete(SKIP_TOKEN_HEADER)
      return next.handle(request)
    } else {
      console.log(request.url)
      if (request.url.indexOf('./') === 0) {
        return next.handle(request)
      } else if (this.authService.getToken()) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.authService.getToken()}`
          }
        })
        return next.handle(request)
      } else {
        return throwError(new Error('Sin sesión activa'))
      }
    }
  }
}
