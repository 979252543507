/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import { BrowserModule } from '@angular/platform-browser'
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injectable, ErrorHandler } from '@angular/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
// import * as Sentry from '@sentry/browser'
import { Userpilot } from 'userpilot'
import { GoogleTagManagerModule } from 'angular-google-tag-manager'

// Rutas
import { AppRoutingModule } from './app-routing.module'

// Environment
import { environment } from '../environments/environment'

// Components
import { AppContainer } from './app.container'

// Modules
import { AuthModule } from './modules/auth/auth.module'
import { SharedModule } from './shared/shared.module'
import { MatButtonModule, MatSnackBarModule } from '@angular/material'
import { NotificationsComponent } from './shared/components'
/*
Sentry.init({
  dsn: 'https://f4176a1f1e8a4919b866989955c803a2@sentry.ragasaapps.com.mx/8',
  environment: environment.env,
  release: environment.version
})
*/

const USER_PILOT_KEY = '88nc37k2'

/*
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor () {}
  handleError (error) {
    if (environment.env !== 'dev') {
      const eventId = Sentry.captureException(error.originalError || error)
      Sentry.showReportDialog({ eventId })
    }
    console.error(error)
  }
}
*/

@NgModule({
  declarations: [
    AppContainer
  ],
  imports: [
    // Third Party
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,

    // Own Modules
    AuthModule,
    SharedModule,
    MatSnackBarModule,
    GoogleTagManagerModule.forRoot({
      id: environment.googleTagManager
    })
  ],
  providers: [
    // TODO: Habilitar Sentry
    // { provide: ErrorHandler, useClass: SentryErrorHandler }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppContainer],
  entryComponents: [NotificationsComponent]
})
export class AppModule {
  constructor () {
    Userpilot.initialize(USER_PILOT_KEY)
  }
}
