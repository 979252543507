/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-constructor */
// Third Party
import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { IDiscussion } from 'shared-ragasa-connect/interfaces'
import { Observable } from 'rxjs'

// Own Interfaces

// Environment
import { environment } from '../../../../environments/environment'

// Utils
import { convertObjectToHttpParams } from '../../../utils'

// Own Constants
import { CONST_PLATFORM } from '../../../shared/constants/settings'

@Injectable()
export class DiscussionService {
  private API_URL = environment.apiUrl

  constructor (
    private http: HttpClient
  ) {
  }

  // API Getters
  getDiscussions (code: string, idObject: string): Observable<IDiscussion[]> {
    const headers: HttpHeaders = new HttpHeaders({
      platform: CONST_PLATFORM
    })
    return this.http
      .get<IDiscussion[]>(`${this.API_URL}discussion/${code}/${idObject}/detail`, { headers })
  }

  postDiscussions (discussion: IDiscussion): Observable<IDiscussion> {
    const headers: HttpHeaders = new HttpHeaders({
      platform: CONST_PLATFORM
    })
    return this.http
      .post<IDiscussion>(`${this.API_URL}discussion`, discussion, { headers })
  }
}
