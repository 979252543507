/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
// Third party
import { Injectable } from '@angular/core'
import { Router, NavigationEnd, Params, ActivatedRoute } from '@angular/router'
import { Observable, Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  totalRoutes: number = 20

  routes: string[] = []

  constructor (
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.addUrlToHistory(event.url)
      }
    })
  }

  addUrlToHistory (url) {
    if (this.routes.length >= this.totalRoutes) {
      this.routes = this.routes.slice(1, this.routes.length)
    }

    this.routes.push(url)
  }

  getPreviusUrl (num: number = 2) {
    if (this.routes.length < num || num < 1) {
      return null
    } else {
      return this.routes[this.routes.length - num]
    }
  }

  getHistory () {
    return this.routes
  }

  getUrlParams (): Observable<Params> {
    return this.activatedRoute
      .queryParams
  }

  getUrlParamsSnapshot () {
    return this.activatedRoute.snapshot.queryParams
  }

  clearHistory () {
    this.routes = []
  }

  setUrlParams (params: Params): Promise<boolean> {
    return this.router
      .navigate([], {
        queryParams: params,
        relativeTo: this.activatedRoute,
        skipLocationChange: false
      })
  }
}
