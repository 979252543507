/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-constructor */import { Component, Injectable } from '@angular/core'
import { MatSnackBar, MatSnackBarConfig } from '@angular/material'

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})

@Injectable({
  providedIn: 'root'
})

export class NotificationsComponent {
  constructor (public snackBar: MatSnackBar) { }

  openSnackBar (message: string, action: string, type: string, duration: number, vPosition: any, hPosition: any) {
    const config = new MatSnackBarConfig()
    config.duration = duration * 1000
    config.verticalPosition = vPosition
    config.horizontalPosition = hPosition
    config.panelClass = [type]
    this.snackBar.open(message, action, config)
  }
}
