
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-constructor */
// Third Party
import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { ISetting } from 'shared-ragasa-connect/interfaces'

// Own Services
import { SettingService } from './../../../../shared/services/settings/setting.service'
// Own Interfaces
import { CONST_SETTING_SOLOGAN, CONST_SETTING_SOLOGAN_EN } from './../../../../shared/constants/settings'
import { TranslateService } from '@ngx-translate/core'

@Component({
  templateUrl: './auth.container.html',
  styleUrls: ['./auth.container.scss']
})
export class AuthContainer implements OnInit {
  isLoading: boolean
  isChrome: boolean = true

  slogan: string = ''

  helpEmail: string

  constructor (
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private settingService: SettingService
  ) {
    this.isLoading = true
    if (this.translate.getBrowserLang() === 'en') {
      this.sloganTextLanguage(CONST_SETTING_SOLOGAN_EN)
    } else {
      this.sloganTextLanguage(CONST_SETTING_SOLOGAN)
    }
    this.helpEmail = 'ragasanext@ragasa.com.mx'
  }

  ngOnInit () {
    if (navigator.userAgent.indexOf('Chrome') !== -1) {
      this.isChrome = false
    }
    console.log(this.router.url)
    // this.goToLogin()
  }

  goToLogin () {
    this
      .router
      .navigate(['login'], {
        relativeTo: this.activatedRoute
      })
  }

  sloganTextLanguage (sloganConst) {
    this.settingService.getSettingByKey(sloganConst).subscribe((slogan: ISetting) => {
      this.slogan = slogan.value
    }).add(() => {
      this.isLoading = false
    })
  }
}
