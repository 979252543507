/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-constructor */
// Third Party
import { Injectable } from '@angular/core'
import { environment } from '../../../../environments/environment'

declare let gtag: (property: string, value: any, configs: any) => {}

@Injectable({
  providedIn: 'root'
})
export class AnalitycsService {
  constructor () {}

  getCurrentTime () {
    // create a ne (local) date object
    var date = new Date()
    // convert it to UTC
    var utcDate = new Date(date.toUTCString())
    // return it in the US default format
    return utcDate.toLocaleString('es-MX')
  }

  setPropertyUser (user: string, role: string, fullName: string, company: string, email: string) {
    let type: string
    if (email.includes('@ragasa')) {
      type = 'Internal User'
    } else {
      type = 'External User'
    }

    gtag('set', 'user_properties', {
      User: user,
      Role: role,
      Fullname: fullName,
      Company: company,
      Application: 'WEB',
      DateSession: this.getCurrentTime(),
      Email: email,
      Type_User: type
    })
  }

  Events (category: string, label: string, action: string, value: any, order: any) {
    gtag('event', label, {
      event_category: category,
      event_label: label,
      event_action: action,
      value: value,
      event_order: order
    })
  }

  Times (category: string, label: string, action: string, value: any) {
    gtag('event', 'timing_complete', {
      name: 'load',
      value: value,
      event_category: category,
      event_label: label,
      event_action: action
    })
  }

  Ecommerce (category: string, items: any | any[], transaction: string, shipment: string, value: number) {
    // currency: Moneda
    // discount: descuento
    // affiliation: afiliacion
    // transaction_id: número de pedido
    // shipping: numero de embarque
    // value: sum o total
    // tax: factura

    gtag('event', category, {
      items: items,
      transaction_id: transaction,
      shipping: shipment,
      value: value
    })
  }

  Config (url: string) {
    gtag('config', environment.googleAnalyticsId, {
      page_title: url
    })
  }
}
