import { LoginComponent } from './login'
import { ActivationCodeComponent } from './activation-code'
import { DialogComponent } from './dialog'
import { PasswordRecoveryComponent } from './password-recovery'
import { ResetPasswordComponent } from './reset-password'

export const AUTH_COMPONENTS = [
  LoginComponent,
  ActivationCodeComponent,
  DialogComponent,
  PasswordRecoveryComponent,
  ResetPasswordComponent
]

export {
  LoginComponent,
  ActivationCodeComponent,
  DialogComponent,
  PasswordRecoveryComponent,
  ResetPasswordComponent
}
