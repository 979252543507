/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-constructor */
// Third Party
import { Component, ViewChild, ElementRef, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

// Own Services
import { AnalitycsService } from '../../../../shared/services'
import { AuthService, IDialog, dialogType } from '../../shared'
import { UserService, USER_SERVICES } from '../../../user/shared'
import { NavigationService } from '../../../../shared/services/navigation'
import { TranslateService } from '@ngx-translate/core'
import { Userpilot } from 'userpilot'

// Own Models
import { ILoginModel } from 'src/app/shared/models/domain'

// Own Components
import { DialogComponent } from '../../components'
import { ISettingsUser, IRole, IUser, ICorporate, IClient } from 'shared-ragasa-connect/internal-interfaces'
@Component({
  templateUrl: './login.container.html',
  styleUrls: ['./login.container.scss'],
  providers: [
    AuthService,
    UserService
  ]
})
export class LoginContainer implements OnInit {
  isLoading: boolean = false

  dialog: IDialog = null

  @ViewChild('dialogComponent', { static: false })
  dialogComponent: ElementRef

  constructor (
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private navigation: NavigationService,
    private translate: TranslateService,
    private analitycs: AnalitycsService
  ) {
  }

  ngOnInit () {
    this.authService.clearLoginInfo()
    this.activatedRoute.queryParams.subscribe(params => {
      this.closeDialog()
      if (params && params.message) {
        this.displayMessage('info', params.message)
      }
    })
    const browserLang = this.translate.getBrowserLang()
    this.translate.use(browserLang.match(/en|es/) ? browserLang : 'en')
  }

  onSubmitLogin (authData: ILoginModel) {
    this.closeDialog()
    this.login(authData)
  }

  onError (error: string) {
    this.displayMessage('error', error, null, true)
  }

  displayMessage (type: dialogType, message: string, timeOut?: number, showCloseButton?: boolean) {
    this.dialog = {
      type,
      message,
      timeOut,
      showCloseButton
    }
  }

  closeDialog () {
    if (this.dialogComponent instanceof DialogComponent) {
      (this.dialogComponent as DialogComponent).close()
    }
  }

  getReleaseLanguage (settings: ISettingsUser[]) {
    return settings.find(f => f.type === 'language')
  }

  login (authData: ILoginModel) {
    this.authService.removeLanguageUser()
    this.navigation.clearHistory()
    this.closeDialog()
    this.isLoading = true
    console.time('login complete')
    this.authService.login(authData)
      .subscribe(async user => {
        console.time('login get data')
        const findLanguage = user.settingsUser === null || Object.values(user.settingsUser).length < 1 ? this.translate.getBrowserLang() : this.getReleaseLanguage(user.settingsUser as ISettingsUser[]).value
        if (findLanguage) {
          this.userService.getUserData(user._id).subscribe((newUser: IUser) => {
            this.authService.setUser(newUser)
            this.authService.setLanguageUser(findLanguage)
            console.timeEnd('login get data')

            const userPilotData = {
              name: user.fullName,
              email: user.email,
              _id: user._id,
              jdeNumber: user.jdeNumber,
              role: (newUser.role as IRole)._id,
              rolename: (newUser.role as IRole).name,
              corporate: (newUser.corporates[0] as ICorporate).jdeNumber,
              client: (newUser.clients[0] as IClient).jdeNumber,
              isOneCorporate: (newUser.corporates.length === 1),
              isOneClient: (newUser.clients.length === 1),
              isClient: (newUser.role as IRole).isClient || false,
              isShippingProgrammer: (newUser.role as IRole).isProgrammer || false,
              language: findLanguage,
              company: {
                id: user.company,
                name: user.company
              }
            }
            console.log('user pilot data')
            console.log(userPilotData)
            Userpilot.identify(user.user, userPilotData)

            if (user.isCodeRequiredForLogin) {
              this.goToActivationCode()
            } else {
              this.authService.setSessionStatus(true)
              this.goToHome()
            }
          })
        }
        console.timeEnd('login complete')
      }, (error: Error) => {
        this.isLoading = false
        this.displayMessage('error', error.message)
      })
  }

  async goToActivationCode () {
    await this.router
      .navigate(['activation-code'], {
        relativeTo: this.activatedRoute.parent
      })
  }

  async onRecoveryPassword () {
    await this.router
      .navigate(['password-recovery'], {
        relativeTo: this.activatedRoute.parent
      })
  }

  async onResetPassword () {
    await this.router
      .navigate(['reset-password'], {
        relativeTo: this.activatedRoute.parent,
        queryParams: {
          token: '12'
        }
      })
  }

  goToHome () {
    const prevUrl: string = this.navigation.getPreviusUrl()
    if (!prevUrl || prevUrl === '/auth/login' || prevUrl.substr(0, 20) === '/auth/reset-password') {
      this.router.navigate(['/', 'home'])
    } else {
      this.router.navigateByUrl(
        prevUrl
      )
    }
  }
}
