/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-constructor */
// Third Party
import { environment } from '../../../../environments/environment'
import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { ISetting } from 'shared-ragasa-connect/interfaces'
// Own Constants
import { SKIP_TOKEN_HEADER } from '../../interceptors/constants'
import { CONST_PLATFORM } from '../../constants/settings'
import { HTTP_STRATEGY_READ_LOCAL } from '../../../shared/interceptors/constants'

const headers: HttpHeaders = new HttpHeaders({
  platform: CONST_PLATFORM,
  httpStrategy: HTTP_STRATEGY_READ_LOCAL
})

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  private apiURL = environment.apiUrl

  constructor (private http: HttpClient) {}

  getSettings (): Observable<ISetting[]> {
    return this.http.get<ISetting[]>(`${this.apiURL}setting`, { headers })
  }

  getSettingById (id: string): Observable<ISetting> {
    return this.http.get<ISetting>(`${this.apiURL}setting/${id}`, { headers })
  }

  getSettingByKey (key: string) {
    const headersC: HttpHeaders = new HttpHeaders({
      [SKIP_TOKEN_HEADER]: SKIP_TOKEN_HEADER,
      platform: CONST_PLATFORM,
      httpStrategy: HTTP_STRATEGY_READ_LOCAL
    })
    return this.http.get<ISetting>(`${this.apiURL}setting-by-key/${key}`, { headers: headersC })
  }

  updateSetting (id: string, setting: ISetting) {
    return this.http.put<ISetting>(`${this.apiURL}setting/${id}`, setting, { headers })
  }
}
