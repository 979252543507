/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-constructor */

// Third Party
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms'

// Own imports Classes
import { ILoginModel } from '../../../../shared/models/domain'
import { platform } from 'os'
// Own Constants
import { CONST_PLATFORM } from '../../../../shared/constants/settings'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'component-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  frmLogin: FormGroup = null

  login: ILoginModel = {
    user: '',
    password: '',
    platform: ''
  }

   error: string = ''

  @Input() set loginData (data: ILoginModel) {
     this.login = {
       user: data.user,
       password: data.password,
       platform: CONST_PLATFORM
     }
     this.frmLogin.controls.username.setValue(this.login.user)
     this.frmLogin.controls.password.setValue(this.login.password)
   }

  @Output() onSubmitLogin: EventEmitter<ILoginModel> = new EventEmitter<ILoginModel>()

  @Output() onError: EventEmitter<string> = new EventEmitter<string>()

  constructor (
    fb: FormBuilder,
    private translate: TranslateService
  ) {
    this.frmLogin = fb.group({
      username: new FormControl(this.login.user, [Validators.required]),
      password: new FormControl(this.login.password, [Validators.required])
    })
  }

  ngOnInit () {
    const browserLang = this.translate.getBrowserLang()
    this.translate.use(browserLang.match(/en|es/) ? browserLang : 'en')
  }

  getErrorMessage (control: AbstractControl): string {
    const err = this.translate.instant('login.messageRequired')
    return control.hasError('required') ? err : ''
  }

  submitLogin (frmLogin: FormGroup) {
    if (frmLogin.invalid) {
      const err = this.translate.instant('login.messageRequired2')
      this.onError.emit(err)
    } else {
      this.onSubmitLogin.emit({
        user: frmLogin.controls.username.value,
        password: frmLogin.controls.password.value,
        platform: CONST_PLATFORM
      })
    }
  }
}
