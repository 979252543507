/* eslint-disable no-unused-vars */
import moment, { Moment } from 'moment'

export function getFirstDayOfMonth (date: Moment | number | Date): Moment {
  if (typeof date === 'number' && (date >= 0 || date <= 11)) {
    return moment().set({ month: date, date: 1 })
  } else if (date instanceof Date) {
    return moment(date).set({ date: 1 })
  } else if (moment.isMoment(date)) {
    return date.set({ date: 1 })
  } else {
    throw new Error('Invalid Month')
  }
}
