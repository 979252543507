/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
// Third party
import { Injectable } from '@angular/core'
import { Router, CanActivate } from '@angular/router'

// Own Services
import { AuthService } from '../../../modules/auth/shared'

@Injectable()
export class ValidateTokenGuard implements CanActivate {
  constructor (
    private authService: AuthService,
    private router: Router
  ) {
  }

  canActivate (): boolean {
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['/', 'auth'])
      return false
    }
    return true
  }
}
