export function rowGridStyles () {
  return ['general-grid-row']
}

export function headerGridStyles () {
  return ['general-grid-header', 'uppercase']
}

export const inlineStyles = {
  gridColumnHeader: {
    'text-align': 'center',
    'font-weight': 'bold',
    'text-transform': 'capitalize'
  },
  gridColumnLeft: {
    'text-align': 'left',
    'font-weight': 'normal',
    'text-transform': 'none'
  },
  gridColumnCenter: {
    'text-align': 'center',
    'font-weight': 'normal',
    'text-transform': 'none'
  },
  gridColumnRight: {
    'text-align': 'right',
    'font-weight': 'normal',
    'text-transform': 'none'
  }
}
