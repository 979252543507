/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-constructor */

// Third Party
import { Component, OnInit, Output, EventEmitter } from '@angular/core'
import { FormControl, Validators, FormBuilder, FormGroup, AbstractControl } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'component-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  readonly errors = {
    required: this.translate.instant('login.required'),
    minlength: this.translate.instant('login.minlength'),
    maxlength: this.translate.instant('login.maxlength')
  }

  frmResetPassword: FormGroup = null

  @Output() onResetPassword: EventEmitter<string> = new EventEmitter<string>()

  @Output() onError: EventEmitter<string> = new EventEmitter<string>()

  constructor (
    fb: FormBuilder,
    private translate: TranslateService
  ) {
    this.frmResetPassword = fb.group({
      password: new FormControl('', [
        Validators.minLength(4),
        Validators.maxLength(20),
        Validators.required
      ]),
      confirmPassword: new FormControl('')
    })
  }

  ngOnInit () {
    const browserLang = this.translate.getBrowserLang()
    this.translate.use(browserLang.match(/en|es/) ? browserLang : 'en')
  }

  getErrorMessage (control: AbstractControl): string {
    let message = ''
    for (const key in this.errors) {
      if (control.hasError(key)) {
        message = this.errors[key]
        break
      }
    }

    return message
  }

  getErrorMessages (form: FormGroup): string {
    let message = ''

    for (const key in form.controls) {
      message += this.getErrorMessage(form.controls[key] as FormControl)
    }

    return message
  }

  resetPassword (frmResetPassword: FormGroup) {
    if (frmResetPassword.invalid) {
      this.onError.emit(this.getErrorMessages(frmResetPassword))
    } else {
      if (frmResetPassword.value.password !== frmResetPassword.value.confirmPassword) {
        this.onError.emit(this.translate.instant('login.errorPasswords'))
      } else {
        this.onResetPassword.emit(frmResetPassword.value.password)
      }
    }
  }
}
