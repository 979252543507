/* eslint-disable no-unused-vars */
/* eslint import/no-duplicates: "off" */
/* eslint import/no-named-default: "off" */
import { Routes, RouterModule } from '@angular/router'
import { NgModule } from '@angular/core'

// Components
import { AppContainer } from './app.container'

// Guards
import { LegalTermsGuard } from './shared/guards'
/*
import { SessionGuard } from './guards/session.guard'
import { AuthGuard } from './guards/auth.guard'
*/

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'auth',
        loadChildren: './modules/auth/auth.module#AuthModule'
      },
      {
        path: 'home',
        loadChildren: './modules/home/home.module#HomeModule',
        canActivate: [LegalTermsGuard]
      },
      {
        path: 'legal-terms',
        loadChildren: './modules/legal-terms/legal-terms.module#LegalTermsModule'
      },
      {
        path: 'externalchat/:name/:fullNameUser/:langUser/:idUser',
        loadChildren: './modules/externalchat/externalchat.module#ExternalChat'
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
