import { LegalTermsGuard } from './legal-terms/legal-terms.guard'
import { ValidateTokenGuard } from './validate-token'

export {
  ValidateTokenGuard,
  LegalTermsGuard
}

export const GUARDS = [
  ValidateTokenGuard,
  LegalTermsGuard
]
