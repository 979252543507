export const environment = {
  production: true,
  apiUrl: 'https://prod-rg-api.azurewebsites.net/api/',
  intUrl: 'https://prod-rn-integration-app.azurewebsites.net/api/',
  containerFilesUrl: 'https://ragasafiles.blob.core.windows.net/ragasa-items/',
  tokenAzureChatBot: 'S__JCQWr3KU.e3fg9_DtDwn0gcN5_vQiszww2dccM0LH9IXJEgMgtTU',
  googleAnalyticsId: 'G-8VFS0WD8GR',
  googleTagManager: 'GTM-5GJKGJ2',
  env: 'prod',
  version: '2.14.2'
}
