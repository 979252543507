/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
// Third Party
import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpHandler } from '@angular/common/http'
import { forkJoin, Observable } from 'rxjs'
import { IRole, ICorporate, IClient, IConsignee, IUser, ILocation } from 'shared-ragasa-connect/interfaces'
import { ApplicationTypeEnum } from 'shared-ragasa-connect/enums'
import { tap } from 'rxjs/operators'

// Environment
import { environment } from '../../../../../../environments/environment'

// Services
import { AuthService } from '../../../../auth/shared'
import { StorageService } from './../../../../../shared/services/storage/storage.service'

// Own Constants
import { CONST_USER_KEY } from '../../../../../shared/constants'
import { CONST_PLATFORM } from '../../../../../shared/constants/settings'
import { HTTP_STRATEGY_READ_LOCAL, HTTP_STRATEGY_READ_REMOTE } from '../../../../../shared/interceptors/constants'

const headers: HttpHeaders = new HttpHeaders({
  platform: CONST_PLATFORM,
  httpStrategy: HTTP_STRATEGY_READ_LOCAL
})

const headersRemote: HttpHeaders = new HttpHeaders({
  platform: CONST_PLATFORM,
  httpStrategy: HTTP_STRATEGY_READ_REMOTE
})

@Injectable()
export class UserService {
  private API_URL = environment.apiUrl

  constructor (
    private http: HttpClient,
    private authService: AuthService,
    private storageService: StorageService
  ) {
  }

  getUsers () {
    const URL = this.API_URL + 'user'
    return this.http.get(URL, { headers })
  }

  getUserById (id: string): Observable<IUser> {
    const url = `${this.API_URL}user/${id}`
    return this.http.get<IUser>(url, { headers })
  }

  createUser (user: IUser): Observable<IUser> {
    const URL = this.API_URL + 'user'
    return this.http.post<IUser>(URL, user, { headers })
  }

  updateUser (id: string, user: IUser): Observable<IUser> {
    const url = `${this.API_URL}user/${id}`
    return this.http.put<IUser>(url, user, { headers })
  }

  getUserData (id: string): Observable<IUser> {
    const url = `${this.API_URL}user/${id}/data`
    return this.http.post<IUser>(url, { headersRemote })
  }

  // Local Getters
  getUserRole (): IRole {
    if (this.authService.isLoggedIn()) {
      const user = this.authService.getUser()
      return user.role as IRole
    }
    throw new Error('You must been logged in')
  }

  getUserClients (): IClient[] {
    if (this.authService.isLoggedIn()) {
      const user = this.authService.getUser()
      return user.clients as IClient[]
    }
    throw new Error('You must been logged in')
  }

  getUserCorporates (): ICorporate[] {
    if (this.authService.isLoggedIn()) {
      const user = this.authService.getUser()
      return user.corporates as ICorporate[]
    }
    throw new Error('You must been logged in')
  }

  getUserConsignees (): IConsignee[] {
    if (this.authService.isLoggedIn()) {
      const user: IUser = this.authService.getUser()
      // extrae todas las consignas de los clientes
      const clientConsignees: IConsignee[] = []
      if (user.clients) {
        user.clients.forEach(c => {
          clientConsignees.push(...c.consignees)
        })
      }
      return clientConsignees
    }
    throw new Error('You must been logged in')
  }

  getUserLocations (): ILocation[] {
    if (this.authService.isLoggedIn()) {
      const user = this.authService.getUser()
      return user.locations as ILocation[]
    }
    throw new Error('You must been logged in')
  }

  getUserCorporateByJDENumber (jdeNumber: number): ICorporate {
    const corporates = this.getUserCorporates()
    if (corporates && corporates.length) {
      const corporate: ICorporate[] = corporates.filter(c => c.jdeNumber === jdeNumber)
      if (corporate && corporate.length) {
        return corporate[0]
      }
      return null
    }
    return null
  }

  getUserClientByJDENumber (jdeNumber: number): IClient {
    const clients = this.getUserClients()
    if (clients && clients.length) {
      const client: IClient[] = clients.filter(c => c.jdeNumber === jdeNumber)
      if (client && client.length) {
        return client[0]
      }
      return null
    }
    return null
  }

  getUserConsigneeByJDENumber (jdeNumber: number): IConsignee {
    const consignees = this.getUserConsignees()
    if (consignees && consignees.length) {
      const consignee: IConsignee[] = consignees.filter(c => c.jdeNumber === jdeNumber)
      if (consignee && consignee.length) {
        return consignee[0]
      }
      return null
    }
    return null
  }

  getFilteredUserCachedCorporates (includeAllItem: boolean = false): (string) => ICorporate[] {
    let coporates = this.getUserCorporates()
    if (includeAllItem) {
      coporates = ([{ name: 'Todo', jdeNumber: null }]).concat(coporates)
    }

    return (corporateDescription) => {
      if (typeof corporateDescription === 'string') {
        const desc = corporateDescription ? corporateDescription.toUpperCase() : ''
        return coporates.filter(c => {
          const jdeNumber = c.jdeNumber ? c.jdeNumber.toString() : ''
          const name = c.name.toUpperCase()

          return jdeNumber.indexOf(desc) >= 0 || name.indexOf(desc) >= 0
        })
      } else {
        return coporates
      }
    }
  }

  getFilteredUserCachedClients (includeAllItem: boolean = false): (string) => IClient[] {
    let clients = this.getUserClients()
    if (includeAllItem) {
      clients = ([{ name: 'Todo', jdeNumber: null }]).concat(clients)
    }

    return (clientDescription) => {
      if (typeof clientDescription === 'string') {
        const desc = clientDescription ? clientDescription.toUpperCase() : ''
        return clients.filter(c => {
          const jdeNumber = c.jdeNumber ? c.jdeNumber.toString() : ''
          const name = c.name.toUpperCase()

          return jdeNumber.indexOf(desc) >= 0 || name.indexOf(desc) >= 0
        })
      } else {
        return clients
      }
    }
  }

  getFilteredUserCachedConsignees (includeAllItem: boolean = true, clientJdeNumber: number = null): (string) => IConsignee[] {
    let consignees: IConsignee[] = []

    if (clientJdeNumber) {
      const client = this.getUserClientByJDENumber(clientJdeNumber)
      consignees = client.consignees as IConsignee[]
    } else {
      consignees = this.getUserConsignees()
    }
    if (includeAllItem) {
      consignees = ([{ name: 'Todo', jdeNumber: null }]).concat(consignees)
    }

    return (consgineeDescription) => {
      if (typeof consgineeDescription === 'string') {
        const desc = consgineeDescription ? consgineeDescription.toUpperCase() : ''
        return consignees.filter(c => {
          const jdeNumber = c.jdeNumber ? c.jdeNumber.toString() : ''
          const name = c.name.toUpperCase()

          return jdeNumber.indexOf(desc) >= 0 || name.indexOf(desc) >= 0
        })
      } else {
        return consignees
      }
    }
  }

  getFilteredConsigneesByClient (includeAllItem: boolean = true) {
    const consignees: IConsignee[] = []

    if (includeAllItem) {
      consignees.push({ name: 'Todo', jdeNumber: null })
    }

    return (clientJdeNumber: number) => {
      if (clientJdeNumber !== undefined) {
        const client = this.getUserClientByJDENumber(clientJdeNumber)

        if (client) {
          for (const consignee of client.consignees as IConsignee []) {
            consignees.push(consignee)
          }
        }
        return consignees
      }
    }
  }

  // Local Setters
  setUserRole (role: IRole): void {
    if (this.authService.isLoggedIn()) {
      const user = this.authService.getUser()
      user.role = role
      this.authService.setUser(user)
      return
    }
    throw new Error('You must been logged in')
  }

  setUserCorporates (corporates: ICorporate[]): void {
    if (this.authService.isLoggedIn()) {
      const user = this.authService.getUser()
      user.corporates = corporates
      this.authService.setUser(user)
      return
    }
    throw new Error('You must been logged in')
  }

  setUserClients (clients: IClient[]): void {
    if (this.authService.isLoggedIn()) {
      const user = this.authService.getUser()
      user.clients = clients
      this.authService.setUser(user)
      return
    }
    throw new Error('You must been logged in')
  }

  setUserConsignees (consignees: IConsignee[]): void {
    if (this.authService.isLoggedIn()) {
      const user = this.authService.getUser()
      user.consignees = consignees
      this.authService.setUser(user)
      return
    }
    throw new Error('You must been logged in')
  }

  setUserLocations (locations: ILocation[]): void {
    if (this.authService.isLoggedIn()) {
      const user = this.authService.getUser()
      user.locations = locations
      this.authService.setUser(user)
      return
    }
    throw new Error('You must been logged in')
  }

  // API Getters
  getRoleByUserId (userId: string): Observable<IRole> {
    return this.http
      .get<IRole>(`${this.API_URL}user/${userId}/role`, { headers })
  }

  getCorporatesByUserId (userId: string): Observable<ICorporate[]> {
    return this.http
      .get<ICorporate[]>(`${this.API_URL}user/${userId}/corporates`, { headers })
  }

  getClientsByUserId (userId: string): Observable<IClient[]> {
    return this.http
      .get<IClient[]>(`${this.API_URL}user/${userId}/clients`, { headers })
  }

  getConsigneesByUserId (userId: string): Observable<IConsignee[]> {
    return this.http
      .get<IConsignee[]>(`${this.API_URL}user/${userId}/consignees`, { headers })
  }

  getLocationsByUserId (userId: string): Observable<ILocation[]> {
    return this.http
      .get<ILocation[]>(`${this.API_URL}user/${userId}/locations`, { headers })
  }

  acceptLegalTerms (userId: string, applicationTypeOrigin: ApplicationTypeEnum) {
    return this.http.post(`${this.API_URL}user/${userId}/accept-legal-terms`, {
      applicationTypeOrigin
    }, { headers }).pipe(
      tap((user: IUser) => {
        const storedUser = this.authService.getUser()
        storedUser.legalTermsAccepted = user.legalTermsAccepted
        storedUser.legalTermsAcceptedAt = user.legalTermsAcceptedAt
        this.storageService.setLocalObject<IUser>(CONST_USER_KEY, storedUser)
      }))
  }
}
