/* eslint-disable no-useless-constructor */
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { HttpClientModule } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import {
  MatInputModule,
  MatFormFieldModule,
  MatButtonModule,
  MatIconModule
} from '@angular/material'

// Own Modules
import { SharedModule } from '../../shared/shared.module'

// Routing
import { AuthRoutingModule } from './auth-routing.module'

// Containers
import { AUTH_CONTAINERS } from './containers'

// Components
import { AUTH_COMPONENTS } from './components'

// Services
import { AUTH_SERVICES } from './shared'

@NgModule({
  declarations: [
    ...AUTH_CONTAINERS,
    ...AUTH_COMPONENTS
  ],
  imports: [
    // Third party
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,

    // Routing
    AuthRoutingModule,

    // Own
    SharedModule
  ],
  providers: [
    ...AUTH_SERVICES
  ],
  exports: [
    ...AUTH_CONTAINERS,
    ...AUTH_COMPONENTS
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AuthModule { }
