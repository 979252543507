export const SKIP_TOKEN_HEADER = 'RC-SKIP-TOKEN'
export const BYPASS_END_SESSION_HEADER = 'RC-BYPASS-END-SESSION'
export const HTTP_STRATEGY = 'httpStrategy'
export const HTTP_STRATEGY_DEFAULT = 'RC_DEFAULT'
export const HTTP_STRATEGY_READ_LOCAL = 'RC_READ_LOCAL'
export const HTTP_STRATEGY_READ_REMOTE = 'RC_READ_REMOTE'
export const HTTP_STRATEGY_WRITE_REMOTE = 'RC_WRITE_REMOTE'
export const HTTP_STRATEGIES = [{
  name: HTTP_STRATEGY_DEFAULT,
  timeout: 60000,
  retryCount: 1,
  scalingDuration: 60000
}, {
  name: HTTP_STRATEGY_READ_LOCAL,
  timeout: 30000,
  retryCount: 1,
  scalingDuration: 30000
}, {
  name: HTTP_STRATEGY_READ_REMOTE,
  timeout: 60000,
  retryCount: 1,
  scalingDuration: 60000
}, {
  name: HTTP_STRATEGY_WRITE_REMOTE,
  timeout: 120000,
  retryCount: 0,
  scalingDuration: 60000
}]
