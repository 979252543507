/* eslint-disable no-unused-vars */
import { Directive, Input, ViewContainerRef, TemplateRef } from '@angular/core'
import { AuthService } from './../../modules/auth/shared'

@Directive({
  selector: '[validatePermission]',
  providers: [AuthService]
})
export class ValidatePermissionDirective {
  actions: any

  constructor (
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private authService: AuthService
  ) {
    this.actions = this.authService.getUserActions()
  }

  @Input() set validatePermission (code: string) {
    if (!code) {
      throw new Error('El codigo de la acción es requerido')
    }
    if (this.actions.indexOf(code.toString()) >= 0) {
      this.viewContainerRef.createEmbeddedView(this.templateRef)
    } else {
      this.viewContainerRef.clear()
    }
  }
}
