import moment from 'moment'

export function dateParser (val) {
  const date = moment(val)
  if (date.isValid()) {
    return date
  } else {
    return undefined
  }
}
